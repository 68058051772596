const initialState = [] ;

function customer(state = initialState, action){
    switch(action.type){
        case 'ADD_TO_CART' :

            const d = state.filter((data)=>{
                return( data.id === action.data.id);
            });

            if(d.length > 0){
                
            }else{
                state = [...state, action.data];
            }

            return state;
        
        
        case 'ADD_QUANTITY' :
            const s = state.map((data,index)=>{
                if(data.id === action.data.id){
                    data.quantity =  data.quantity + 1;
                }

                return(data);

            })
            state = s;

            return state;


        case 'REMOVE_QUANTITY' :
            let rid ;

            const ss = state.map((data,index)=>{
                if(data.id === action.data.id){
                    if(data.quantity === 1){
                        rid = data.id;
                    }else{
                        data.quantity =  data.quantity - 1;
                    }
                }

                return(data);
            })

            const a = ss.filter((data,index)=>{
                return(data.id !== rid)
  
            })


            state = a;

            return state;

        case 'ADD_COLOR' :
                const c = state.map((data)=>{
                    if(data.id === action.data.id){
                        data.color = action.data.color;
                    }
    
                    return(data);
    
                })
                state = c;
    
                return state;
            case 'ADD_SIZE' :
                const sz = state.map((data)=>{
                    if(data.id === action.data.id){
                        data.size = action.data.size;
                    }
    
                    return(data);
    
                })
                state = sz;
    
                return state;

        case 'REMOVE_FROM_CART' :
            const dcc = state.filter((data)=>{
                return( data.id !== action.data.id);
            });

            state = [...dcc];

            return state;

        case 'REMOVE_CART' :

            state = [];

            return state;

        default : return state;
    }

}

export default customer;