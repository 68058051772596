import React, { useEffect } from 'react';
import { Link, Element, Events, scroller } from 'react-scroll';
import { NavLink, useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Nav from '../components/Nav';

import { useSelector} from 'react-redux';
import Cart from '../components/Cart';


export default function AllServices(){

  
    const serviceGroup = useSelector((state)=>state.category);
    const { id } = useParams();
    
    function scrollTo() {
        scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        offset: -100,
        smooth: 'easeInOutQuart'
        })
    }



    useEffect(()=>{
        window.scrollTo(0,0)
        setTimeout(()=>{
            const element = document.getElementById(id);
            element.scrollIntoView();
        },1000)

    },[id])


      useEffect(()=>{
        return ()=>{
            Events.scrollEvent.remove('begin');
            Events.scrollEvent.remove('end');
        }
      })

        return (<>
            <Nav />
            <div className=' w-full h-max pt-20 md:pl-4 flex justify-center '>
                <div className="w-40  lg:w-72 xl:w-96 h-auto  relative border-r border-slate-400">
                    <h1 className='  sm:text-3xl pb-4'>All Category</h1>
                    <div className=' allService  w-full flex flex-col h-max sticky top-16 '>
                        {
                            serviceGroup.map((data, index)=>{
                                return(
                                    <Link key={index} activeClass="active" className=" flex flex-col md:flex-row items-center w-full my-1 px-2 text-slate-700 cursor-pointer" to={data.name} spy={true} smooth={true} offset={-100} duration={500} >
                                        <img className=' h-8 m-2' src={data.img} alt="" />
                                        <span className=' text-xs sm:text-lg'>{data.name}</span>
                                    </Link>
                                )
                            })
                        }

                    </div>

                </div>

                <div className=' w-3/5 h-auto p-4 '>
                    {
                        serviceGroup.map((data, index)=>{
                            return(
                                <Element key={index} name={data.name} className=" h-auto pb-20" >
                                    <h1 id={data.name.replace(/ /gi, "_")} className=' font-bold text-lg md:text-3xl pb-4'>{data.name}</h1>
                                    <div className=' flex flex-col md:flex-row md:flex-wrap gap-6'>
                                    {
                                        data.services.map((data,index)=>{
                                            return(
                                                <NavLink key={index} to={"/subCategory/"+data._id}>
                                                    <div key={index}  className=' flex items-center md:flex-col w-full md:w-48 lg:w-48 grow xl:grow-0 '>
                                                        <img className=' w-12 md:w-full rounded-md' src={data.image.img} alt="" />
                                                        <h1 className=' w-full text-xs md:text-base lg:text-xl pl-2 md:pl-0 md:text-center'> {data.name}</h1>
                                                    </div>
                                                </NavLink>
                                            )
                                        })
                                    }


                                    </div>
                                </Element>
                            )
                        })
                    }

                </div>

            </div>
            <Cart />
            <Footer />

        </> );
}
