import React from 'react';
import { Link } from 'react-router-dom';
import logo2 from '../assets/images/logo.png'
import paly from '../assets/images/play.png';
import Chat from './Chat';
function Footer() {

    const date = new Date();


  return (<>

    <footer className=' w-full h-auto md:h-80 lg:h-72 flex flex-col md:flex-row items-center md:justify-evenly xl:px-20 bg-gray-800 '>

        <div className=' w-[320px] h-full p-4 flex flex-col gap-5 items-center text-white'>
            <img className=' w-40' src={logo2} alt="" />
            <h1>Connect With Our Social Media</h1>
            <div className=' flex gap-4'>
                <a className=' text-3xl hover:text-orange-600 transition-colors' href="https://www.facebook.com/EasyShebaPlatform"><i className="fa-brands fa-facebook-square"></i></a>
                <a className=' text-3xl hover:text-orange-600 transition-colors' href="https://easysheba.com/"><i className="fa-brands fa-youtube-square"></i></a>
                <a className=' text-3xl hover:text-orange-600 transition-colors' href="https://easysheba.com/"><i className="fa-brands fa-instagram-square"></i></a>
                <a className=' text-3xl hover:text-orange-600 transition-colors' href="https://www.linkedin.com/company/31311047/admin/"><i className="fa-brands fa-linkedin"></i></a>
            </div>
            <h1 >Download Our App</h1>
            <a href="https://play.google.com/store/apps/details?id=multiplexer.lab.easysheba" target="_blank" rel="noreferrer" ><img className=' w-32' src={paly} alt="" /> </a>
        </div>
        <div className=' w-80 lg:w-96 h-full p-4 flex flex-col gap-2 items-center text-white'>
            <h1 className=' text-xl md:text-lg mt-3 pb-4'>OTHER PAGES</h1>
            <Link to="">About Us</Link>
            <Link to="/blog">Blog</Link>
            <Link to="/career">Career</Link>
            <Link to="">Help</Link>
            <Link to="">Terms and Conditions</Link>
            <Link to="">Privacy and Policies</Link>
        </div>
        {/* <div className='w-80 lg:w-96 h-full p-4 flex flex-col gap-2 items-center md:items-start text-white'>
            <h1 className=' text-xl md:text-lg mt-3 pb-4'>COMPANIES</h1>
            <a href="http://digitalearning.xyz/" target="_blank" rel="noreferrer">Digital Learning XYZ</a>
            <Link to="">Agriona Food Ltd</Link>
            <Link to="">Bhidu Agro Ltd</Link>
        </div> */}
        <div className='w-80 lg:w-max h-full p-4 flex flex-col gap-2 items-center md:items-start text-white'>
            <h1 className=' text-xl md:text-lg mt-3 pb-4'>CONTACT US</h1>
            <div>
                <div className=' w-max float-left pr-2'><i className="fa-solid fa-phone"></i></div>
                <span className=' float-left'><a href="tel:09696-010506"> 09696-010506</a>  <br /><a href="tel:09696-010506"> 01966-050506</a></span>
            </div>
            <div>
                <div className=' w-max float-left pr-2'><i className="fa-solid fa-envelope"></i></div>
                <span className=' float-left'><a href="mailto:info@easysheba.com"> info@easysheba.com</a></span>
            </div>
            <div>
                <div className=' w-max float-left pl-1 pr-2'><i className="fa-solid fa-location-dot"></i></div>
                <span className=' float-left'>House #5, Road #3 <br />Block E, Banasree, <br />Rampura, Dhaka-1219</span>
            </div>
        </div>
    </footer>
    <Chat />
    <div className=' text-sm md:text-base p-4 md:p-0 w-full md:leading-60 bg-slate-600 text-white text-center'><span>Copyright @{date.getFullYear()} | EasySheba Platform Limited | All Rights Reserved</span></div>
    </>)
}

export default Footer;