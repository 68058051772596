import React from "react";
import { NavLink } from 'react-router-dom';
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css"; 
import "../../node_modules/slick-carousel/slick/slick-theme.css";



function ServiceSlider({data}){


  function SampleNextArrow(props) {
      const { onClick } = props;
      return (
        // -mr-10 -mt-[90px] sm:-mt-[130px] md:-mt-[130px] lg:-mt-[110px] xl:-mt-[150px]
        <div
          className=" hidden w-10 h-10 z-10 -mr-10 -mt-[90px] sm:-mt-[130px] md:-mt-[130px] lg:-mt-[120px] xl:-mt-[120px] 2xl:-mt-[170px] rounded-full font-bold text-3xl xl:text-4xl  text-orange-600 bg-slate-100 float-right lg:flex items-center justify-center cursor-pointer"
          onClick={onClick}
        ><i className="fa-solid fa-angle-right"></i></div>            
        );
    }
    
    function SamplePrevArrow(props) {
      const { onClick } = props;
      return (
        // -ml-9  mt-[20px] sm:mt-16 md:mt-[50px] lg:mt-[70px] xl:mt-[80px] 
        <div
        className=" hidden lg:flex w-10 h-10 -ml-9  mt-[20px] sm:mt-16 md:mt-[50px] lg:mt-[120px] xl:mt-[160px]   rounded-full font-bold text-3xl xl:text-4xl text-orange-600 bg-slate-100 float-left items-center justify-center cursor-pointer"
          onClick={onClick}
        ><i className="fa-solid fa-angle-left"></i></div>
      );
    }

      var settings = {
        dots: false,
        infinite: true,
        autoplay: true,
        speed: 900,
        autoplaySpeed: 4000,
        slidesToShow: 4,
        slidesToScroll: 2,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 2,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          }
        ]
      };
      return (
        <div className=" pt-1 bg-white rounded-md ">
          <Slider {...settings}>
            {
              data.map((data,index)=>{
                return(
                  <NavLink className=" xl:w-60 xl:h-40 px-1 text-center flex items-center justify-center relative" key={index} to={"/subCategory/"+data._id}>
                    {/* <div className="  w-60 h-80 px-4 text-center flex items-center justify-center cursor-pointer"> */}
                      <img src={data.image.img} className="w-full rounded-md" alt="" />
                      <h1 className=" text-xs sm:text-base font-bold text-gray-700">{data.name}</h1>
                    {/* </div> */}
                  </NavLink>
                )
              })
            }

          </Slider>
        </div>
      );
  }

  export default ServiceSlider;