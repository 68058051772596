import React from "react";
import Slider from "react-slick";
import "../../node_modules/slick-carousel/slick/slick.css"; 
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import { useSelector} from 'react-redux';
import { NavLink } from 'react-router-dom'


function ServiceGroupSlider(){

  const serviceGroup = useSelector((state)=>state.category);
    
        function SampleNextArrow(props) {
            const { onClick } = props;
            return (
              <div
                className=" w-10 h-10 z-10 -mr-10 rounded-full font-bold text-3xl  xl:text-4xl md:-mt-[60px] xl:-mt-[60px] 2xl:-mt-[65px]  text-orange-600 bg-slate-100 float-right flex items-center justify-center cursor-pointer"
                onClick={onClick}
              ><i className="fa-solid fa-angle-right"></i></div> 
              );
          }
          
          function SamplePrevArrow(props) {
            const { onClick } = props;
            return (
              <div
              className=" w-10 h-10  rounded-full font-bold -ml-9 text-3xl xl:text-4xl mt-[20px] text-orange-600 bg-slate-100 float-left flex items-center justify-center cursor-pointer"
                onClick={onClick}
              ><i className="fa-solid fa-angle-left"></i></div>
            );
          }

      var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 7,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1290,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: false

            }
          }
        ]
      };
      return (
        <div className=" m-5 p-5 bg-white rounded-md shadow-md">
          <Slider {...settings}>
            {
              serviceGroup.map((data, index)=>{
                return(
                  <NavLink className=" w-20 h-20 text-center flex items-center justify-center" key={index} to={"allCategorys/"+data.name.replace(/ /gi, "_")} >
                        <img src={data.img} className=" px-14 h-10" alt="" />
                        <h1 className=" text-center">{data.name}</h1>
                  </NavLink>
                )
              })
            }

          </Slider>
        </div>
      );
  }

  export default ServiceGroupSlider;