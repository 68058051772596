import React,{ useEffect } from 'react'
import Footer from '../components/Footer';
import NavHome from '../components/NavHome';
import ServiceGroupSlider from '../components/ServiceGroupSlider';
import ServiceSlider from '../components/ServiceSlider';
import Rbanner from '..//assets/images/web-banner.png';
import Rbanner2 from '..//assets/images/web-banner2.jpg';
import Cart from '../components/Cart';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Nav from '../components/Nav';
import FlashSale from './FlashSale';
import { useState } from 'react';
import url from '../url';
import FlashSaleSlider from '../components/FlashSaleSlider';
import { ADD_TO_CART, ADD_QUANTITY, REMOVE_QUANTITY } from '../store/actions/cart';


function Home() {


  const forHome = useSelector((state)=>state.forHome);
  const tranding = useSelector((state)=>state.tranding);
  const RecentlyViews = useSelector((state)=>state.RecentlyViews);
  const serviceGroup = useSelector((state)=>state.category);
  const [flashSale, setFlashSale] = useState({packages:[], endTime: Date.now()+10000, status:''});
  const [product, setProduct] =  useState([]);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();


  useEffect(()=>{
    fetch(`${url}/package/flashSale`,{
      method:"GET",
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setFlashSale(data.data);
      }
    });
  },[]);

  useEffect(()=>{
    fetch(`${url}/package?limit="20"&page="1"`,{
      method:"GET",
      credentials: 'include'
    }).then((data)=>data.json()).then((data)=>{
      if(data.status === true){
        setProduct(data.result.data);
      }
    });
  },[]);
  
  useEffect(()=>{
    window.scrollTo(0,0)

  },[]);

  return (
    <>
    <div className=" hidden md:block">
      <NavHome />
    </div>
    <div className=" block md:hidden">
        <Nav />
    </div>
      <div className=' hidden md:block md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px] mx-auto -mt-20'>
        <ServiceGroupSlider />
      </div>

      <div className=" block md:hidden mt-20 box-border">
        {
          (serviceGroup.length > 0)?
          <table className=" w-full">
            <thead></thead>
            <tbody className=" w-full flex flex-col justify-center p-1">
              <tr className=" w-full">
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[11].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[11].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[11].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[7].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[7].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[7].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[6].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[6].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[6].name}</h1>
                  </NavLink>
                </td>
                <td className="w-[180px] h-max border md:p-1 ">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[0].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[0].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[0].name}</h1>
                  </NavLink>
                </td>
              </tr>
              <tr className=" w-full">
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[4].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[4].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[4].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[3].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[3].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[3].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[8].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[8].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[8].name}</h1>
                  </NavLink>
                </td>
                <td className="w-[180px] h-max border md:p-1 ">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[2].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[2].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[2].name}</h1>
                  </NavLink>
                </td>
              </tr>
              <tr className=" w-full">
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[1].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[1].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[1].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[9].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[9].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs">{serviceGroup[9].name}</h1>
                  </NavLink>
                </td>
                <td className=" w-[180px] h-max border md:p-1">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to={"allCategorys/"+serviceGroup[10].name.replace(/ /gi, "_")} >
                        <img src={serviceGroup[10].img} className=" w-10 h-10" alt="" />
                        <h1 className=" text-xs ">{serviceGroup[10].name}</h1>
                  </NavLink>
                </td>
                <td className="w-[180px] h-max border md:p-1 ">
                <NavLink className=" w-16 h-auto mx-auto text-center flex flex-col items-center justify-center" to="allCategorys" >
                <i className="fa-solid fa-table-cells text-4xl text-orange-600"></i>
                        <h1 className=" text-xs ">All_Categorys</h1>
                  </NavLink>
                </td>
              </tr>
            </tbody>
          </table>:
          <></>
        }
      </div>

      <div className=' w-full sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px] flex flex-col md:flex-row gap-4  mx-auto mt-5 xl:mt-16 2xl:mt-20'>
        <img className=' md:-ml-1 w-full md:w-1/2' src={Rbanner2} alt="" />
        <img className=' w-full md:w-1/2' src={Rbanner} alt="" />
      </div>
      {
        (flashSale.status === "ON" && flashSale.endTime > Date.now())?
        <div className=' w-full px-1 pb-5 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px]  mx-auto mt-5 xl:mt-16 2xl:mt-20'>
          <h1 className=' p-2 font-bold bg-slate-100 '><span className='text-xl lg:text-3xl'>FLASH SALE</span> <FlashSale endTime={flashSale.endTime} /></h1>
          {/* <h1 className=' text-center'></h1> */}
          <FlashSaleSlider data={flashSale.packages} />
        </div>:<></>
      }


      <div className=' w-full px-1 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px]  mx-auto mt-5 xl:mt-16 2xl:mt-20'>
        <h1 className=' text-lg lg:text-2xl p-2 font-bold bg-slate-100'>For Your Home</h1>
        <ServiceSlider data={forHome} />
      </div>

      {
        (RecentlyViews.length > 3)?     
          <div className='  w-full px-1 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px]  mx-auto mt-5 xl:mt-16'>
            <h1 className=' text-lg lg:text-2xl font-bold p-2 bg-slate-100'>Recently Views</h1>
            <ServiceSlider data={RecentlyViews} />
          </div>:<></>
      }

      <div className='  w-full px-1 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px]  mx-auto mt-5 xl:mt-16 pb-10 '>
        <h1 className='text-lg lg:text-2xl font-bold p-2 bg-slate-100'>Trending</h1>
        <ServiceSlider data={tranding} />
      </div>
        
      <div className='  w-full px-1 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px]  mx-auto mt-5 xl:mt-16 pb-10 '>
        <div className=' w-full mt-5 flex items-center justify-between'>
          <div className=' w-full h-[5px] bg-slate-300 rounded-[100%]'></div>
          <h1 className=' shrink-0 px-4 text-xl font-bold'>New Product</h1>
          <div className=' w-full h-[5px] bg-slate-300 rounded-[100%]'></div>
        </div>
        <div className=" py-10 flex flex-wrap flex-col md:flex-row justify-center items-center gap-4">
          {
            product.map((data, index)=>{
              const d = cart.filter((cart)=>{
                  return(cart.id === data._id)
              });
              return(
              <div key={index} className=" w-[250px] h-max border border-orange-600 py-5 rounded-md shrink-0 flex flex-col items-center justify-center">
                  <div><img className=' w-full' src={data.img} alt="" /></div>
                  <div className=" ">
                    <h1 className=" text-center font-bold text-slate-600"><span>{data.quantity}{data.unit.name}</span></h1>
                    <h1 className=" text-center">
                      <span className=" text-2xl text-orange-600 font-bold">{data.discountPrice}৳ </span>

                      {

                        (data.price === data.discountPrice)?<></>:<span className=" line-through">{data.price}৳ </span>
                      }
                      
                      </h1>
                    <h1 className=" text-center"><span className=" text-lg text-slate-600 font-bold">{data.name} </span></h1>
                  </div>
                  <div className=' flex gap-2 pt-4 '>
                    {
                        (d[0])?
                        <div className=' w-28 border-2 border-orange-600 rounded-md flex justify-between'>
                            <button onClick={()=> dispatch(REMOVE_QUANTITY({id:data._id}))} className=' bg-orange-600 text-white px-[5px]'><i className="fa-solid fa-minus"></i></button>
                            <span className=' text-orange-600'>{d[0].quantity}</span>
                            <button onClick={()=> dispatch(ADD_QUANTITY({id:data._id}))} className=' bg-orange-600 text-white px-[5px]'><i className="fa-solid fa-plus"></i></button>
                        </div>
                        :
                            (data.discountPrice > 0)?
                            <button onClick={()=>dispatch(ADD_TO_CART({"id":data._id, "name":data.name, size:"", color:"", "pPrice":data.purchasePrice, "price":data.price, "discountPrice":data.discountPrice,"unit":data.unit.shortName,"pdQuantity":data.quantity, "quantity":1 }))} className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>Add to cart</button>
                            :
                            <button onClick={()=>dispatch(ADD_TO_CART({"id":data._id, "name":data.name, size:"", color:"", "pPrice":data.purchasePrice, "price":data.price, "discountPrice":data.price,"unit":data.unit.shortName,"pdQuantity":data.quantity, "quantity":1 }))} className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>Add to cart</button>
                    }
                                                

                    <NavLink to={'/product/'+data._id}><button className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>View Product</button></NavLink>
                </div>
                </div>
              )
            })
          }
        </div>
        
      </div>
      <Cart />
      <Footer />

    </>
  )
}

export default Home;