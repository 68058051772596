import React, { useState } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import avatar from '../assets/images/avatar.webp'
import { useEffect } from 'react';
import url from '../url';
import useAuth from '../hooks/useAuth';
import { useCookies } from 'react-cookie';
import Cart from './Cart';
import Chat from './Chat';

function DSLayout(){

    const [sideBasr, setSideBasr] = useState('-ml-[400px]');
    const [customer, setCustomer] = useState({});
    const [cookies, setCookie, removeCookie] = useCookies(['auth']);

    const navigate = useNavigate();

    const { id } = useAuth();

    useEffect(()=>{
        fetch(`${url}/customer/auth/${id}`,{
            method:"GET",
            credentials: 'include'
        }).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setCustomer(data.data)
            }
        });
    },[id]);

    function set(){
      if(sideBasr === '-ml-[400px]'){
        setSideBasr('-ml-[0px]');
      }else{
        setSideBasr('-ml-[400px]');
      }
    }

    const date = new Date();
    const d = date.toLocaleDateString();

    function logOut(){

        removeCookie('user',[{expires: Date.now()}]);
    
        fetch(`${url}/customer/logout/${id}`,{method:'DELETE',mode:'cors',credentials:'include'}).then((data)=>data.json()).then((data)=>{
            navigate('/logIn');

        });
    
      }
    

    return(
        <>
            <nav className={` h-16 bg-slate-100 shadow-md w-full flex px-5 sm:px-16 xl:px-40 fixed top-0 left-0 z-50 transition-all duration-700 ease-in-out `}>
              <div className=' w-full flex items-center justify-between bg-none'>
                  <div><Link to='/'><img className=' w-32' src={logo} alt="" /></Link></div>
                  <div className=' flex gap-5'>
                      <span className=' hidden md:block text-orange-600 hover:text-orange-500 font-bold'>{d}</span>
                  </div>
              </div>
              <button onClick={set} className=' block md:hidden text-2xl border-none outline-none text-orange-600'><i className="fa-solid fa-bars"></i></button>
            </nav>
            <div className=' w-full flex pt-16 '>
                <div className={` ${sideBasr} transition-all duration-500 ease-in-out	 absolute  md:relative md:-ml-[0px] w-72 h-max shrink-0 bg-slate-200 p-4 z-10`}>
                    <img className=' w-full rounded-md' src={ (customer.img)? customer.img:avatar} alt="" />
                    <div className=' pt-2'>
                        <div>
                            <span className=' font-bold'>Name: </span>
                            <span>{customer.name}</span>
                        </div>
                        <div>
                            <span className=' font-bold'>Email: </span>
                            <span>{customer.email}</span>
                        </div>
                        <div>
                            <span className=' font-bold'>Phone: </span>
                            <span>{customer.phone}</span>
                        </div>
                        <div>
                            <span className=' font-bold'>Address: </span>
                            <span>{customer.address}</span>
                        </div>
                    </div>
                    <div className=' mt-10 flex flex-col gap-4'>
                        <Link className=' w-full bg-blue-600 text-white font-bold px-4 py-1 rounded-sm' to="/dashboard" > <i className="fa-solid fa-chart-column"></i> <span> Dashboard</span></Link>
                        <Link className=' w-full bg-green-600 text-white font-bold px-4 py-1 rounded-sm' to="/changeInfo" > <i className="fa-solid fa-pen-to-square"></i> <span>Change Info</span></Link>
                        <Link className=' w-full bg-orange-600 text-white font-bold px-4 py-1 rounded-sm' to="/reword" > <i className="fa-solid fa-sack-dollar"></i> <span>Reward Points</span></Link>
                        <Link className=' w-full bg-yellow-600 text-white font-bold px-4 py-1 rounded-sm' to="/changePassword" > <i className="fa-solid fa-unlock"></i> <span>Change Password</span></Link>
                        <button onClick={logOut} className=' w-full bg-red-600 text-white font-bold px-4 py-1 rounded-sm'> <i className="fa-solid fa-power-off"></i> <span> Log Out</span></button>
                    </div>
                </div>
                <div className=' w-full h-full p-4 overflow-auto'>
                  <Outlet />
                </div>
            </div>
            <Cart />
            <Chat />
            
        </>
    )
}

export default DSLayout;