import React from 'react';

import { useSelector } from 'react-redux';

function PackageDetails({ clss, setClss}){

    const data  = useSelector((state)=>state.ViewPacakage);


    function hide(){
        setClss({display:"none"});
    }


    return(
        <div style={clss} className={` w-80 md:w-[560px] lg:w-[760px] xl:w-[1000px] h-[420px] md:h-[500px] lg:h-[600px] bg-slate-100 rounded-md shadow-md fixed top-20 left-[5%] md:left-[20%] p-2 overflow-auto `}>
            <div onClick={hide} className='w-max h-max cursor-pointer text-4xl font-bold text-red-600 hover:text-red-700 absolute right-3'><i className="fa-solid fa-xmark"></i></div>
            <h1><span className=' font-bold'>Name: </span>{data.name}</h1>
            <h3>
                <span className=' font-bold'>Price : </span> 
                {
                    (data.discountPrice > 0)? <span className=' text-red-600 line-through'>{data.price}  ৳</span> :<></>
                }
                {
                    (data.discountPrice > 0)? <span> {data.discountPrice}  ৳</span> :<span>{data.price}  ৳</span>
                }
            </h3>
            {
                (data.unit === undefined)?<></>:<h3><span className=' font-bold'>Quantity: </span><span>{data.quantity}</span> <span>{data.unit.shortName}</span></h3>
            }
            <div className=' text-justify'><span className=' font-bold'>Description: </span> <div dangerouslySetInnerHTML={{ __html: data.description }}></div></div>
        </div>
    )

}

export default PackageDetails;