import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
// import { Link, Element, Events, scroller } from 'react-scroll';
import Cart from '../components/Cart';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import url from '../url';
import { ADD_RECENTLY_VIEWS } from '../store/actions/RecentlyViews';
import { ADD_TO_CART, ADD_QUANTITY, REMOVE_QUANTITY } from '../store/actions/cart';
// import { VIEW_PACKAGE } from '../store/actions/ViewPacakage';



function ViewService() {

    const [service, setService] = useState({name:'', banner:{img:""}, includes:[],excludes:[], faqs:[], details:{html:""}});
    const [product, setProduct] = useState([]);
    const cart = useSelector((state) => state.cart);
    // const [clss, setClss] = useState({display:"none"});
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(()=>{
        fetch(`${url}/service/${id}`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{
          setService(data.data);
          dispatch(ADD_RECENTLY_VIEWS(data.data));
        
        });

      },[id, dispatch]);

      useEffect(()=>{
        fetch(`${url}/package/search/?search=${id}`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{

          if(data.status === true){
            setProduct(data.result.data);
        
          }
        
        });

      },[id]);



    // function scrollTo() {
    //   scroller.scrollTo('scroll-to-element', {
    //   duration: 800,
    //   delay: 0,
    //   offset: -100,
    //   smooth: 'easeInOutQuart'
    //   })
    // }
  

    // useEffect(()=>{
    //   return ()=>{
    //       Events.scrollEvent.remove('begin');
    //       Events.scrollEvent.remove('end');
    //   }
    // });

    useEffect(()=>{
      window.scrollTo(0,0)
  
    },[]);

  return (
    <>
        <Nav />
        <div className=" w-full sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px] h-auto mx-auto mt-20 relative flex flex-col items-center">
          <img className=' ' src={service.banner.img} alt="" />
          <div className=' w-full mt-5 flex items-center justify-between'>
            <div className=' w-full h-[5px] bg-slate-300 rounded-[100%]'></div>
            <h1 className=' shrink-0 px-4 text-xl font-bold'>{service.name}</h1>
            <div className=' w-full h-[5px] bg-slate-300 rounded-[100%]'></div>
          </div>
          <div className=" py-10 flex flex-col md:flex-row gap-4">
            {
            product.map((data, index)=>{
              const d = cart.filter((cart)=>{
                  return(cart.id === data._id)
              });
              return(
                <div key={index} className=" w-[250px] h-max border border-orange-600 py-5 rounded-md flex flex-col items-center justify-center">
                  <div><img className=' w-full' src={data.img} alt="" /></div>
                  <div className=" ">
                    <h1 className=" text-center font-bold text-slate-600"><span>{data.quantity}{data.unit.name}</span></h1>
                    <h1 className=" text-center">
                      <span className=" text-2xl text-orange-600 font-bold">{data.discountPrice}৳ </span>

                      {

                        (data.price === data.discountPrice)?<></>:<span className=" line-through">{data.price}৳ </span>
                      }
                      
                      </h1>
                    <h1 className=" text-center"><span className=" text-xl text-slate-600 font-bold">{data.name} </span></h1>
                  </div>
                  <div className=' flex gap-2 pt-4 '>
                    {
                        (d[0])?
                        <div className=' w-28 border-2 border-orange-600 rounded-md flex justify-between'>
                            <button onClick={()=> dispatch(REMOVE_QUANTITY({id:data._id}))} className=' bg-orange-600 text-white px-[5px]'><i className="fa-solid fa-minus"></i></button>
                            <span className=' text-orange-600'>{d[0].quantity}</span>
                            <button onClick={()=> dispatch(ADD_QUANTITY({id:data._id}))} className=' bg-orange-600 text-white px-[5px]'><i className="fa-solid fa-plus"></i></button>
                        </div>
                        :
                            (data.discountPrice > 0)?
                            <button onClick={()=>dispatch(ADD_TO_CART({"id":data._id, "name":data.name, size:"", color:"", "pPrice":data.purchasePrice, "price":data.price, "discountPrice":data.discountPrice,"unit":data.unit.shortName,"pdQuantity":data.quantity, "quantity":1 }))} className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>Add to cart</button>
                            :
                            <button onClick={()=>dispatch(ADD_TO_CART({"id":data._id, "name":data.name, size:"", color:"", "pPrice":data.purchasePrice, "price":data.price, "discountPrice":data.price,"unit":data.unit.shortName,"pdQuantity":data.quantity, "quantity":1 }))} className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>Add to cart</button>
                    }
                                                

                    <NavLink to={'/product/'+data._id}><button className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>View Product</button></NavLink>
                </div>
                </div>
              )
            })
          }
        </div>
        </div>
        <Cart />
        <Footer />
    </>
  )
}

export default ViewService;