import React from 'react';
import Countdown, {zeroPad} from 'react-countdown';


function FlashSale({endTime}){


    const Completionist = () => <span></span>;
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        return <Completionist />;
    } else {
        return <span className=" pl-10 text-orange-600 text-xl lg:text-3xl">{zeroPad(days)}:{zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}</span>;
    }
    };


    const n = new Date();
    const time = endTime - n.getTime()

    return(
        <>
          <Countdown date={Date.now() + parseInt(time)} renderer={renderer} />
        </>
    )
}

export default FlashSale;