import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import Footer from '../components/Footer';
import Nav from '../components/Nav';
import url from '../url';
import { ADD_TO_CART, ADD_QUANTITY, REMOVE_QUANTITY, ADD_COLOR, ADD_SIZE } from '../store/actions/cart';
import { useDispatch, useSelector } from 'react-redux';
import Cart from '../components//Cart';


function ViewProduct(){
    const [product, setProduct] = useState({img:'', unit:{name:''}});
    const { id } = useParams();
    const [color, setColor] = useState("");
    const [size, setSize] = useState("");
    const cart = useSelector((state) => state.cart);
    const dispatch = useDispatch();

    useEffect(()    =>{
        fetch(`${url}/package/${id}`).then((data)=>data.json()).then((data)=>{
            if(data.status === true){
                setProduct(data.data);

                if(data.data.color){
                    setColor(data.data.colors[0]);
                }

                if(data.data.size){
                    setSize(data.data.sizes[0]);
                }
            }
        })
    }, [id]);
    


    let d = cart.filter((cart)=>{
        return(cart.id === product._id)
    });

    useEffect(()=>{
        window.scrollTo(0,0)
    
        },[]);

    return(
        <div>
            <Nav/>
            <div className=" w-full bg-slate-100 sm:w-[600px] md:w-[700px] lg:w-[950px] xl:w-[1100px] 2xl:w-[1400px] h-auto mx-auto mt-20 p-4 my-10 shadow-md flex flex-col md:flex-row">
                <div className=" md:w-[40%] flex md:justify-start justify-center">
                    <img className=" rounded-md" src={product.img} alt="" />
                </div>
                <div className=" md:w-[60%] p-4 pt-0">
                    <h1 className=" text-lg md:text-2xl font-bold">{product.name}</h1>
                    <h3>{product.quantity}{product.unit.name}</h3>
                    <h2>
                        <span className=' text-2xl font-bold'>{product.discountPrice}৳ </span>
                        {
                            (product.price === product.discountPrice)?<></>:<span className=" line-through text-red-500">  {product.price}৳</span>
                        }
                    </h2>
                    <div className=" flex flex-wrap gap-3">
                        <div className='w-[150px] flex flex-col gap-2 mt-5'>
                            {
                                (d[0])?
                                <div className=' w-28 border-2 border-orange-600 rounded-md flex justify-between'>
                                    <button onClick={()=> dispatch(REMOVE_QUANTITY({id:product._id}))} className=' bg-orange-600 text-white px-[5px]'><i className="fa-solid fa-minus"></i></button>
                                    <span className=' text-orange-600'>{d[0].quantity}</span>
                                    <button onClick={()=> dispatch(ADD_QUANTITY({id:product._id}))} className=' bg-orange-600 text-white px-[5px]'><i className="fa-solid fa-plus"></i></button>
                                </div>
                                :
                                    (product.discountPrice > 0)?
                                    <button onClick={()=>dispatch(ADD_TO_CART({"id":product._id, "name":product.name, size, color, "pPrice":product.purchasePrice, "price":product.price, "discountPrice":product.discountPrice,"unit":product.unit.shortName,"pdQuantity":product.quantity, "quantity":1 }))} className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>Add to cart</button>
                                    :
                                    <button onClick={()=>dispatch(ADD_TO_CART({"id":product._id, "name":product.name, size, color, "pPrice":product.purchasePrice, "price":product.price, "discountPrice":product.price,"unit":product.unit.shortName,"pdQuantity":product.quantity, "quantity":1 }))} className=' px-2 py-[2px] bg-orange-600 text-white rounded-md'>Add to cart</button>
                            }
                                                        
                        </div>
                        <div className=" md:pl-4 flex gap-3 items-center">
                            {
                            ( product.size === true)?
                                    <div className=" w-[100px] flex flex-col">
                                        <label className=" text-orange-600" htmlFor="size">Select Size:</label>
                                        <select className=" border-none outline-none rounded-md" onChange={(e)=>{ 
                                            setSize(e.target.value);
                                            dispatch(ADD_SIZE({id:product._id, size:e.target.value}));
                                            
                                            }} name="size" id="size" value={size}>
                                            {
                                                product.sizes.map((data, index)=>{
                                                    return(<option key={index} value={data}>{data}</option>)
                                                })
                                            }
                                            
                                        </select>
                                    </div>:<></>
                            }

                            {
                            ( product.color === true)?
                                <div className=" w-[100px] flex flex-col">
                                    <label className=" text-orange-600" htmlFor="color">Select Color:</label>
                                    <select className=" border-none outline-none rounded-md" onChange={(e)=>{
                                        setColor(e.target.value);
                                        dispatch(ADD_COLOR({id:product._id, color:e.target.value}));
                                        }} name="color" id="color" value={color}>
                                        {
                                            product.colors.map((data, index)=>{
                                                return(<option key={index} value={data}>{data}</option>)
                                            })
                                        }
                                        
                                    </select>
                                </div>:<></>
                            }
                        </div>
                    </div>
                    <div className=' pt-10 text-justify'  dangerouslySetInnerHTML={{ __html: product.description }}></div>
                </div>
            </div>
            <Cart />
            <Footer />
        </div>
    )
}


export default ViewProduct;