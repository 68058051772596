import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import url from '../url';

function Sale(){

    const [purchase, setPurchase] = useState({_id:'', cart:[], receivable:0, received:0, due:0, customerName:"", customerEmail:"", customerPhone:"", customerAddress:"", totalPrice: 0, discount:0, quantity:0, date:''});
    const {id} = useParams();

    useEffect(()=>{

        fetch(`${url}/sale/f/${id}`,{credentials: 'include'}).then((data)=>data.json()).then((data)=>{
            setPurchase(data.data);
        });

    },[id]);

    const d = new Date(purchase.date);
    const dat = d.toDateString();

    useEffect(()=>{
        window.scrollTo(0,0)
    
      },[]);

    return(
            <div className=" bg-white drop-shadow-md w-11/12 mx-auto mt-4 rounded-sm  h-max p-4">
                {/* <a href={"/purchase/print/"+id} rel="noreferrer" target="_blank"><i className="fa-solid fa-print text-orange-500"></i></a>
                <div className="text-center">
                    <div className=" w-full flex justify-center"><img className=" w-52" src={logo} alt="" /></div>
                    <div className=" text-sm"><p>House #5, Road #3 Block E, Banasree, Rampura, Dhaka-1219</p></div>
                    <div className=" text-sm"><i className="fa-solid fa-phone"></i> 01966-050506</div>
                    <div className=" text-sm"><i className="fa-regular fa-envelope"></i> info@easysheba.com </div>
                    <div className=" text-sm"><i className="fa-solid fa-earth-americas"></i> https://easysheba.com</div>
                </div> */}
                <div className=" rounded-md m-1 flex flex-col md:justify-evenly md:flex-row text-xs sm:text-sm md:text-base pb-20">
                    <table className=" w-full table-auto border-collapse border-slate-500 mt-10">
                        <thead>
                            <tr className=" text-left border-slate-500 border-b">
                                <th>Item Name</th>
                                <th>Size</th>
                                <th>Color</th>
                                <th>Sale price</th>
                                <th>Discount price</th>
                                <th>Quantity</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                purchase.cart.map((data,index)=>{
                                    return(
                                    <tr key={index} className=" border-slate-500 border-b">
                                    <td>{data.name}</td>
                                    <td>{data.size}</td>
                                    <td>{data.color}</td>
                                    <td>{data.price}  ৳</td>
                                    <td>{data.discountPrice}  ৳</td>
                                    <td>{data.quantity}</td>
                                    <td>{data.discountPrice * data.quantity}  ৳</td>
                                </tr>)
                                })
                            }

                            <tr >
                                <td></td>
                                <td></td>
                                <th className="  text-left">Discount :</th>
                                <td className=" ">{purchase.discount}  ৳</td>
                            </tr>

                            <tr >
                                <td></td>
                                <td></td>
                                <th className="  text-left">Payable :</th>
                                <td className="  ">{purchase.receivable - purchase.discount}  ৳</td>
                            </tr>
                            <tr >
                                <td></td>
                                <td></td>
                                <th className=" border-slate-500 border-b text-left">Payed :</th>
                                <td className=" border-slate-500 border-b  ">{purchase.received}  ৳</td>
                            </tr>
                            <tr >
                                <td></td>
                                <td></td>
                                <th className="  text-left">Due :</th>
                                <td className=" ">{purchase.due - purchase.discount}  ৳</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className=" text-xs sm:text-sm md:text-base relative -mt-10">
                    <b> Date : </b>{dat}
                </div>
            </div>
    );
}

export default Sale;