

export function ADD_TO_CART(data){
    return({type:'ADD_TO_CART',data});
}

export function ADD_QUANTITY(data){
    return({type:'ADD_QUANTITY',data});
}

export function REMOVE_QUANTITY(data){
    return({type:'REMOVE_QUANTITY',data});
}

export function  REMOVE_FROM_CART(data){
    return({type:'REMOVE_FROM_CART',data});
}

export function  REMOVE_CART(){
    return({type:'REMOVE_CART'});
}


export function ADD_COLOR(data){
    return({type:'ADD_COLOR',data});
}

export function ADD_SIZE(data){
    return({type:'ADD_SIZE',data});
}